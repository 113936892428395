<template>
    <div class="container repair-container" v-if="revisit">
        <h2 class="text-center">Review and book the revisit</h2>
        <div class="card card-body pb-0">
            <h5>Device</h5>

            <dl>
                <dd>{{revisit.device.title}}</dd>
                <dt>
                    IMEI: <template v-if="revisit.device.imei">{{revisit.device.imei}}</template><template v-if="!revisit.device.imei">Not supplied</template>
                </dt>
            </dl>
        </div>

        <div class="card card-body pb-0">
            <h5>Problems</h5>

            <dl v-for="(problem, index) in revisit.problems" :key="index">
                <dd>{{problem.title}}</dd>
                <dt v-for="(symptom, symptomIndex) in problem.symptoms" :key="symptomIndex+Math.random()">
                    {{symptom.title}}
                </dt>
            </dl>
        </div>

        <div class="card card-body pb-0">
            <h5>Repair Parts</h5>
            <dl>
                <dt>Parts</dt>
                <dd v-for="(stockItem, index) in revisit.stock_items" :key="index">
                    {{stockItem.title}}
                </dd>
            </dl>
        </div>

        <div class="card card-body pb-0">
            <h5>Repair Details</h5>

            <dl>
                <dt>Repair Address</dt>
                <dd>
                    {{revisit.address.line_1}},
                    <template v-if="revisit.address.line_2">{{revisit.address.line_2}},</template>
                    {{revisit.address.town}},
                    <span class="text-uppercase">{{revisit.address.postcode}}</span>
                </dd>
            </dl>
            <dl>
                <dt>Repair Date</dt>
                <dd>{{revisit.day.week_day}} {{revisit.day.full_date}}</dd>
            </dl>
            <dl>
                <dt>Timeslot</dt>
                <dd>This will sent to the customer at 08:00 on the morning of your repair.</dd>
            </dl>
        </div>

        <button @click="confirmBooking" class="btn btn-block btn-lg btn-success" :disabled="booking">
            <template v-if="!booking">Book Revisit</template>
            <template v-if="booking"><animated-loading class="mr-2" size="sm"></animated-loading> Booking revisit</template>
        </button>

        <!-- Footer -->
        <fixed-footer
                :class="{'active': true}"
                :prevRoute="`revisit.repair-date`">
        </fixed-footer>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "ConfirmRevisit",

        data() {
            return {
                booking: false,
                revisit: null
            }
        },

        mounted() {
            this.loadBooking();
        },

        methods: {
            ...mapActions([
                "displayToast"
            ]),

            loadBooking() {
                this.$http.get(`/api/v4/warranty/booking/${localStorage.revisitBookingId}`).then(res => {
                    this.revisit = res.data.data;
                });
            },

            confirmBooking() {
                this.booking = true;
                this.$http.post(`/api/v4/warranty/booking/${localStorage.revisitBookingId}/confirm`).then(() => {
                    this.booking = false;
                    this.$router.push({name: 'job', params:{revisitBooked:true}});
                }).catch((error) => {
                    this.booking = false;
                    this.displayToast({text: error, type: 'danger'});
                })
            }
        }
    }
</script>

<style scoped>

</style>